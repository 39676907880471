<template>
    <div>

        <el-form   label-width="120px">
            <el-form-item label="地址:">
                <el-tag
                        :key="tag"
                        v-for="tag in dynamicTags"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(tag)">
                    {{tag}}
                </el-tag>
                <el-input
                        class="input-new-tag"
                        v-if="inputVisible"
                        v-model="inputValue"
                        ref="saveTagInput"
                        size="mini"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="mini" @click="showInput">添加地址</el-button>
            </el-form-item>
            <el-form-item label="">
                <el-button  size="mini" type="primary" @click="saveAddress">确定保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<style>

</style>
<script>
    export default {
        data(){
            return {
                dynamicTags: [],
                inputVisible: false,
                inputValue: '',
            }
        },
        mounted(){
            document.title='会员地址'
            this.getAddress()
        },
        methods:{
            getAddress(){
                let _this=this
                this.$post('/pc/member/getAddress',{},function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.$message.success(result.msg)
                        if(result.data.member_address){
                            let address = result.data.member_address
                            let len = address.length - 1
                            address = address.slice(0,len)
                            _this.dynamicTags = address.split(',')
                        }
                    }
                })
            },
            saveAddress(){
                if(this.dynamicTags.length==0) return this.$message.error('请添加地址后在保存')
                let _this=this
                let address_str = ''

                for(let i in _this.dynamicTags){
                    address_str = address_str + _this.dynamicTags[i]+','
                }
                this.$post('/pc/member/addAddress',{address_str:address_str},function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.$message.success(result.msg)
                    }
                })



            },
            handleClose(tag) {
                this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.dynamicTags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            }
        }
    }
</script>
